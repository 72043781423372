import i18next from "i18next";
import i18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enTranslation from "./Lang/en.json";
import frTranslation from "./Lang/fr.json";
import deTranslation from "./Lang/de.json";

import gaTranslation from "./Lang/GA.json";
import grTranslation from "./Lang/gr.json";
import esTranslation from "./Lang/es.json";
import huTranslation from "./Lang/hu.json";
import tkTranslation from "./Lang/tk.json";
import seTranslation from "./Lang/se.json";
import ptTranslation from "./Lang/pt.json";
import plTranslation from "./Lang/pl.json";
import noTranslation from "./Lang/no.json";
import nlTranslation from "./Lang/nl.json";
import itTranslation from "./Lang/it.json";

const resources = {
  de: { translation: deTranslation },
  en: { translation: enTranslation },
  es: { translation: esTranslation },
  fr: { translation: frTranslation },
  ga: { translation: gaTranslation },
  gr: { translation: grTranslation },
  hu: { translation: huTranslation },
  tk: { translation: tkTranslation },
  se: { translation: seTranslation },
  pt: { translation: ptTranslation },
  pl: { translation: plTranslation },
  no: { translation: noTranslation },
  nl: { translation: nlTranslation },
  it: { translation: itTranslation },
};

i18next.use(i18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources,
      lng: "en",
      fallbackLng: "en",
      debug: true,
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: ["cookie", "localStorage", "navigator", "htmlTag"],
        caches: ["cookie"],
      },
      react: {
        useSuspense: false,
      },
    },
    (err, t) => {
      if (err) {
        console.error(err);
      } else {
        const dir = i18next.language === "ar" ? "rtl" : "ltr";
        document.documentElement.setAttribute("dir", dir);
      }
    }
  );

export default i18next;