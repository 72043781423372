import {
    faCheckCircle,
    faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useRef, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";
import { FareHeaderView } from "WebApiClient";
import IncServices from "components/Shared/IncServices";
import { SabreIconsEmoji } from "./IncludedServiceIcons/SabreIcons";
import { State } from 'rootExports/rootReducer';
import { useSelector } from "react-redux";

interface IncludedServicesProps {
    readonly Data: FareHeaderView;
    readonly Fareindex: number;
    readonly BaggageType: string;
    FareTypeInfo: any;
    mealCode: string;
}

interface IconEntry {
    icon: string; // Could be a string (for emojis) or an import (for image paths)
    hint: string; // Description or hint associated with the icon
}

const FareHeaderIncludedServices: React.FC<IncludedServicesProps> = (props) => {
    const iconDataDefaultHeight: number = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings[0]?.defaultHeight);
    const iconData = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings);
    function getNumberWithStyledX(input: string) {
        // Match only "2PC" or "3PC"
        const match = input.match(/^([23])P(C)?$/);

        if (match) {
            const number = match[1];
            // Return styled JSX
            return (
                <span className="pcStyle">
                    {number}x
                </span>
            );
        }

        return null; // Return null if no match
    }
    // Call SabreIconsEmoji and store all icons
    const allIcons: Record<string, IconEntry> = SabreIconsEmoji(iconData);

    function checkBaggageTypeLength(baggageType: string) {
        // Extracting the numeric part using a regular expression
        var numericPart = baggageType.match(/\d+/);
        // Check if numericPart exists and return its length, otherwise return 0
        return numericPart ? numericPart[0].length : 0;
    }

    function checkBaggageType() {
        if (props.BaggageType == "01P") {
            return (
                <span className="baggageServicesSpanStyle">
                    <Tooltip
                        title={
                            <span>{props.BaggageType}</span>
                        }
                    >
                        <span>
                            <span className={checkBaggageTypeLength(props?.BaggageType) == 1 ? "baggageCodeInner1" : "baggageCodeInner"}>  </span>
                            {/* <span>{SabreIconsEmoji()?.["01P"]?.icon}</span> */}
                            <img style={{}} src={`${allIcons?.["Checked baggage"]?.icon}`} height={`${iconDataDefaultHeight}px`} alt="hitchhiker" />
                        </span>
                    </Tooltip>
                </span>
            );
        }
        if (props.BaggageType == "02P") {
            return (
                <span className="baggageServicesSpanStyle">
                    <Tooltip
                        title={
                            <span>{props.BaggageType}</span>
                        }
                    >
                        <span>
                            {/* <span className={checkBaggageTypeLength(props?.BaggageType) == 1 ? "baggageCodeInner1" : "baggageCodeInner"}>
                                {
                                    extractNumbers(props.BaggageType)
                                }
                            </span> */}
                            {/* <span>{SabreIconsEmoji()?.["01P"]?.icon}</span> */}
                            <img style={{}} src={`${allIcons?.["Checked baggage"]?.icon}`} height={`${iconDataDefaultHeight}px`} alt="hitchhiker" />
                        </span>
                    </Tooltip>
                </span>
            );
        }
        if (props.BaggageType == "25K") {
            return (
                <span className="baggageServicesSpanStyle">
                    <Tooltip
                        title={
                            <span>{props.BaggageType}</span>
                        }
                    >
                        <span>
                            {/* <span className={checkBaggageTypeLength(props?.BaggageType) == 1 ? "baggageCodeInner1" : "baggageCodeInner"}>
                                {extractNumbers(props.BaggageType)}
                            </span> */}
                            {/* <span>{SabreIconsEmoji()?.["01P"]?.icon}</span> */}
                            <img style={{}} src={`${allIcons?.["Checked baggage"]?.icon}`} height={`${iconDataDefaultHeight}px`} alt="hitchhiker" />
                        </span>
                    </Tooltip>
                </span>
            );
        }
        if (props.BaggageType == "") {
            return (
                <span className="baggageServicesSpanStyle">
                    <Tooltip
                        title={
                            <span>This fare has free baggage allowance for at least one connection</span>
                        }
                    >
                        <span>
                            {/* <span>{SabreIconsEmoji()?.["01P"]?.icon}</span> */}
                            <img style={{}} src={`${allIcons?.["Checked baggage"]?.icon}`} height={`${iconDataDefaultHeight}px`} alt="hitchhiker" />
                        </span>
                    </Tooltip>
                </span>
            );
        }
        return (
            <span className="baggageServicesSpanStyle">
                <Tooltip
                    title={
                        <span>{props.BaggageType}</span>
                    }
                >
                    <span >
                        {/* <span className={checkBaggageTypeLength(props?.BaggageType) == 1 ? "baggageCodeInner1" : "baggageCodeInner"}>
                            {props.BaggageType == "1P" ? "" :
                                props.BaggageType == "0K" ? "" :
                                    props.BaggageType == "01P" ? "" :
                                        props.BaggageType == "1PC" ? "" :
                                            extractNumbers(props.BaggageType)
                            }
                        </span> */}
                        {/* <span>{SabreIconsEmoji()?.["01P"]?.icon}</span> */}
                        {getNumberWithStyledX(props.BaggageType)}<img style={{}} src={`${allIcons?.["Checked baggage"]?.icon}`} height={`${iconDataDefaultHeight}px`} alt="hitchhiker" />
                    </span>
                </Tooltip>
            </span>
        );
    }

    //mealcode make array
    const mealCodeArray = _.split(props.mealCode, ",");



    const getRightIcon = (code: string) => {
        // Assuming SabreIconsEmoji() returns the JSON object
        const data: string[] = Object.keys(allIcons);
        if (data.includes(code)) {
            // If code is found in data, return the code
            return code;
        } else {
            // If code is not found, return the first character of the code
            const firstCharacter = code.charAt(0);
            return firstCharacter;
        }
    };

    return (
        <div
            className="ancillaries align-bottom min-w-100px d-flex align-items-end"
            style={{
                width: "118px",
                position: 'relative',
                flexWrap: 'wrap'
            }}>

            {/* {props.Data.includedServices!.length > 0 && <span> | </span>} */}
            <span style={{ display: 'flex', alignItems: 'start', gap: '2px', flexWrap: 'wrap' }}>
                <>
                    {props.BaggageType === "" ? (
                        props.Data.fareHasFreeBaggage == false ? (
                            <FreeBaggeIndicator HasFreeBaggage={props.Data.fareHasFreeBaggage} />
                        ) : (
                            checkBaggageType()
                        )
                    ) : (
                        checkBaggageType()
                    )}
                </>

                <>
                    {props.Data.includedServices!.map((mainGroup, mainIndex) => (
                        <IncServices
                            key={"IncServices" + mainIndex}
                            data={mainGroup}
                            toShow={false}
                            iconDataDefaultHeight={iconDataDefaultHeight}
                            fullToolTip={true}
                            includedServices={props.Data.includedServices}
                        />
                    ))}
                </>

            </span>
            {mealCodeArray?.map((iconD: any, iMeal: number) => (
                <span key={iMeal} className="baggageServicesSpanStyle">
                    <Tooltip
                        title={
                            <>
                                <span>
                                    {allIcons?.[iconD]?.hint !== undefined ? allIcons?.[iconD]?.hint : iconD}
                                </span>
                            </>
                        }
                    >
                        <span>
                            {iconD == "" || iconD == "N" ? null :
                                <img src={`${allIcons?.[getRightIcon(iconD)]?.icon}`}
                                    height={`${iconDataDefaultHeight}px`}
                                    alt={iconD}
                                />
                            }
                        </span>
                    </Tooltip>
                </span>
            ))}
        </div>
    );
};

export default FareHeaderIncludedServices;
interface FreeBaggeIndicatorProps {
    HasFreeBaggage: boolean;
}

const FreeBaggeIndicator: React.FC<FreeBaggeIndicatorProps> = ({ HasFreeBaggage }) => {
    const iconDataDefaultHeight: number = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings[0]?.defaultHeight);
    const iconData = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings);
    const title = HasFreeBaggage
        ? "This fare has free baggage allowance for at least one connection"
        : "This fare has no free baggage allowance.";
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const elemRef = useRef<HTMLSpanElement>(null);
    const allIcons: Record<string, IconEntry> = SabreIconsEmoji(iconData);
    return (
        <React.Fragment>
            <Popover target={elemRef} isOpen={open} toggle={toggle}>
                <PopoverBody>{title}</PopoverBody>
            </Popover>
            <span
                className=" fa-fw"
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                onTouchStart={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
                role="tooltip"
                onTouchEnd={() => setOpen(false)}
                ref={elemRef}
            >
                {/* <FontAwesomeIcon
                    style={{ opacity: HasFreeBaggage ? 1 : 0.5 }}
                    title={title}
                    icon={faLuggageCart}
                /> */}
                <img src={`${allIcons?.["No bags"]?.icon}`} alt={`${allIcons?.["No bags"]?.hint}`} height={`${iconDataDefaultHeight}px`} style={{ opacity: HasFreeBaggage ? 1 : 0.9 }} />
                {/* <img src={`${allIcons?.["Checked baggage"]?.icon}`} alt="cart" /> Hiii */}
                {/* <FontAwesomeIcon
                    style={
                        {
                            "--fa-secondary-opacity": "0.7",
                            "--fa-primary-color": "black",
                            color: `${HasFreeBaggage ? "green" : "red"}`,
                            position: "absolute",
                            top: "10px",

                        } as CSSProperties
                    }
                    icon={
                        HasFreeBaggage ? faCheckCircle : faExclamationTriangle
                    }
                    aria-hidden="true"
                    // color={HasFreeBaggage ? "green" : "red"}
                    transform="shrink-7 down-6 right-5"
                /> */}
            </span>
        </React.Fragment>
    );
};